html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  background: #eeeee4 !important;
  z-index: 1;
  background-image: url("../resources/img/fondo.png") !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: center !important;
  background-size: cover !important;
  height: 100vh !important;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");

* {
  box-sizing: border-box;
}

.responsive-card {
  width: 500px;
}

.turnos-card {
  width: 800px;
}

.turnos-card div {
  line-height: 0 !important;
}

.inpemail {
  width: 400px !important;
}

.inpnrotur {
  width: 150px !important;
}

@media screen and (max-width: 800px) {
  .responsive-card {
    width: 300px;
  }
  .turnos-card {
    width: 300px;
  }

  .inpemail {
    width: 250px !important;
  }
}
